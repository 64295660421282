import * as Getter from './ValueGetters';
import * as Formatter from './ValueFormatters';
import moment from "moment";
import {getParamsData} from "./Utils";


export const MONTH = {
    headerName: "Month",
    field: "month_start",
    colId: 'month_start',
    valueGetter: Getter.monthGetter,
    type: "dimension",
    enableRowGroup: true,
    cellRenderer: (params) => {
        let month_start = moment(params.value, "YYYY-MM-DD");
        month_start = month_start.isBefore(moment(params.startDate)) ? moment(params.startDate) : month_start;
        return moment(month_start).format("YYYY-MM-DD");
    },
    hide: true
}
export const WEEK = {
    headerName: "Week",
    colId: 'week_start',
    field: "week_start",
    valueGetter: Getter.weekGetter,
    type: "dimension",
    enableRowGroup: true,
    cellRenderer: (params) => {
        let week_start = moment(params.value, "YYYY-MM-DD");
        week_start = week_start.isBefore(moment(params.startDate)) ? moment(params.startDate) : week_start;
        return moment(week_start).format("YYYY-MM-DD");
    },
    hide: true
}
export const WEEK_NORMALIZED = {
    headerName: "Week",
    field: "normalized_week_start",
    colId: "normalized_week_start",
    valueGetter: (params) => {
        let dt = getParamsData(params);
        return dt ? dt.normalized_week_start : "";
    },
    type: "custom",
    enableRowGroup: true,
    cellRenderer: (params) => {
        let week_start = moment(params.value, "YYYY-MM-DD");
        week_start = week_start.isBefore(moment(params.startDate)) ? moment(params.startDate) : week_start;
        return moment(week_start).format("YYYY-MM-DD");
    },
    hide: true
}

export const FULL_APP_NAME = {
    colId: 'full_app_name',
    headerName: 'Application',
    field: 'full_app_name',
    type: 'dimension',
    hide: true
};
export const TITLE = {
    colId: 'title',
    headerName: 'Title',
    field: 'app_name',
    type: 'dimension',
    hide: true
};
export const PLATFORM = {
    colId: 'platform',
    headerName: 'Platform',
    field: 'platform',
    type: 'dimension',
    hide: true
};
export const TRAFFIC_TYPE = {
    colId: 'trafficType',
    headerName: 'Traffic type',
    field: 'traffic_type',
    type: 'dimension',
    hide: true
};
export const DATE = {
    colId: 'install_date',
    headerName: 'Date',
    field: 'install_date',
    valueGetter: Getter.dateGetter,
    type: 'dimension',
    hide: true
};
export const SOURCE = {
    colId: 'source',
    headerName: 'Source',
    field: 'source',
    type: 'dimension',
    hide: true
};
export const MEDIA_SOURCE = {
    colId: 'media_source',
    headerName: 'Media Source',
    field: 'media_source',
    type: 'dimension',
    hide: true
};
export const FB_COUNTRY_TYPE = {
    colId: 'fbCountryType',
    headerName: 'Fb Country Type',
    field: 'country_type',
    type: 'dimension',
    hide: true
};
export const CAMPAIGN = {
    colId: 'campaign',
    headerName: 'Campaign',
    field: 'campaign',
    type: 'dimension',
    cellRenderer: 'campaignActivityRenderer',
    hide: true
};
export const FB_PLACEMENT = {
    colId: 'fbPlacement',
    headerName: 'Fb Placement',
    field: 'placement',
    type: 'dimension',
    hide: true
};
export const FB_OPTIMIZATION = {
    colId: 'fbOptimizationType',
    headerName: 'Fb Optimization Type',
    field: 'optimization_type',
    type: 'dimension',
    hide: true
};
export const FB_AUDIENCE = {
    colId: 'fbAudienceType',
    headerName: 'Fb Audience Type',
    field: 'audience_type',
    type: 'dimension',
    hide: true
};
export const FB_DEVICE = {
    colId: 'fbDeviceType',
    headerName: 'Fb Device Type',
    field: 'fb_device_type',
    type: 'dimension',
    hide: true
};
export const ADSET = {
    colId: 'adset',
    headerName: 'Adset',
    field: 'adset',
    type: 'dimension',
    hide: true
};
export const COUNTRY = {
    colId: 'country_code',
    headerName: 'Country',
    field: 'country_code',
    type: 'dimension',
    hide: true
};
export const STATE = {
    colId: 'state',
    headerName: 'State',
    field: 'state',
    type: 'dimension',
    hide: true
};
export const CITY = {
    colId: 'city',
    headerName: 'City',
    field: 'city',
    type: 'dimension',
    hide: true
};
export const LANGUAGE = {
    colId: 'language',
    headerName: 'Language',
    field: 'language',
    type: 'dimension',
    hide: true
};
export const SITEID = {
    colId: 'site_id',
    headerName: 'Site ID',
    field: 'site_id',
    type: 'dimension',
    hide: true
};
export const SITE_NAME = {
    colId: 'site_name',
    headerName: 'Site Name',
    field: 'site_name',
    type: 'dimension',
    hide: true
};
export const ADJUST_SITEID = {
    colId: 'adjustSiteid',
    headerName: 'Site ID',
    field: 'siteid',
    type: 'dimension',
    hide: true
};
export const ADJUST_SUB_SITEID = {
    colId: 'adjustSubSiteId',
    headerName: 'Sub Site ID',
    field: 'sub_siteid',
    type: 'dimension',
    hide: true
};
export const AD = {
    colId: 'ad',
    headerName: 'Ad',
    field: 'ad',
    type: 'dimension',
    hide: true
};
export const EXCHANGE = {
    colId: 'exchange',
    headerName: 'Exchange',
    field: 'channel',
    type: 'dimension',
    hide: true
};
export const KEYWORDS = {
    colId: 'keywords',
    headerName: 'Keywords',
    field: 'keywords',
    type: 'dimension',
    hide: true
};
export const CREATIVE = {
    colId: 'creative',
    headerName: 'Creative',
    field: 'creative',
    type: 'dimension',
    hide: true
};
export const DEVICE_CATEGORY = {
    colId: 'deviceCategory',
    headerName: 'Device Category',
    field: 'device_category',
    type: 'dimension',
    hide: true
};
export const OS_VERSION = {
    colId: 'osVersion',
    headerName: 'OS Version',
    field: 'os_version',
    type: 'dimension',
    hide: true
};
export const CREATIVE_CONCEPT = {
    colId: 'creativeConcept',
    headerName: 'Concept',
    field: 'creative_concept',
    type: 'dimension',
    hide: true
};
export const CREATIVE_FULL_NAME = {
    colId: 'creativeName',
    headerName: 'Creative Full Name',
    field: 'creative_name',
    type: 'dimension',
    hide: true
};
export const CREATIVE_NAME_ALL_ENDCARD = {
    colId: 'creativeNameWithAllEndCard',
    headerName: 'Creative Name All End Card',
    field: 'creative_name_with_all_endcard',
    type: 'dimension',
    hide: true
};
export const CREATIVE_NAME_PLAYABLE_ENDCARD = {
    colId: 'creativeNameWithPlayableEndCard',
    headerName: 'Creative Name Playable End Card',
    field: 'creative_name_with_playable_endcard',
    type: 'dimension',
    hide: true
};
export const CREATIVE_NAME_NO_ENDCARD = {
    colId: 'creativeNameWithoutEndCard',
    headerName: 'Creative Name No End Card',
    field: 'creative_name_without_endcard',
    type: 'dimension',
    hide: true
};
export const LOCALIZATION = {
    colId: 'localization',
    headerName: 'Localization',
    field: 'localization',
    type: 'dimension',
    hide: true
};
export const DEVICE_TYPE = {
    colId: 'deviceType',
    headerName: 'Device',
    field: 'device_type',
    type: 'dimension',
    hide: true
};
export const MATCH_TYPE = {
    colId: 'matchType',
    headerName: 'Match Type',
    field: 'match_type',
    type: 'dimension',
    hide: true
};
export const RELEASE = {
    colId: 'release',
    headerName: 'Release',
    field: 'release_name',
    type: 'dimension',
    hide: true
};
export const INSTALLS = {
    colId: 'installs',
    headerName: 'Installs',
    field: 'installs',
    aggFunc: 'sum',
    type: 'numericColumn',
    width: 60,
    valueFormatter: Formatter.numberFormatter(0),
};
export const ASO_INSTALLS = {
    colId: 'installs_aso',
    headerName: "Installs",
    field: "installs_aso",
    aggFunc: 'sum',
    type: "numericColumn",
    valueFormatter: Formatter.numberFormatter(0),
};
export const ASO_ALL_INSTALLS = {
    colId: 'installs',
    headerName: 'All Installs',
    field: 'installs',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(0),
};
export const LTVPPU = {
    colId: 'ltvppu',
    headerName: 'LTVppu',
    sortable: false,
    valueGetter: Getter.ltvPputGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(0)
};
export const PAYERS_PERCENT = {
    colId: 'payersPercent',
    headerName: 'Payers %',
    sortable: false,
    valueGetter: Getter.payersGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(1),
    hide: true
};
export const CPA = {
    colId: 'cpa',
    headerName: 'CPA',
    sortable: false,
    valueGetter: Getter.cpaGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(1),
    hide: true
};
export const CPA1D = {
    colId: 'cpa1d',
    headerName: 'CPA 1D',
    sortable: false,
    valueGetter: Getter.cpa1Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpa1TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};
export const CPA2D = {
    colId: 'cpa2d',
    headerName: 'CPA 2D',
    sortable: false,
    valueGetter: Getter.cpa2Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpa2TooltipGetter,
    type: 'numericColumn',
    width: 50,
    cellRenderer: Formatter.incompleteDayRenderer(3),
    hide: true
};
export const CPA3D = {
    colId: 'cpa3d',
    headerName: 'CPA 3D',
    sortable: false,
    valueGetter: Getter.cpa3Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpa3TooltipGetter,
    type: 'numericColumn',
    width: 50,
    cellRenderer: Formatter.incompleteDayRenderer(3),
};
export const CPA7D = {
    colId: 'cpa7d',
    headerName: 'CPA 7D',
    sortable: false,
    valueGetter: Getter.cpa7Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpa7TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(7),
    hide: true
};
export const CPA14D = {
    colId: 'cpa14d',
    headerName: 'CPA 14D',
    sortable: false,
    valueGetter: Getter.cpa14Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpa14TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(14),
    hide: true
};
export const CPP = {
    colId: 'cpp',
    headerName: 'CPP',
    sortable: false,
    valueGetter: Getter.cppGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(1),
    hide: true
};
export const CPP1D = {
    colId: 'cpp1d',
    headerName: 'CPP 1D',
    sortable: false,
    valueGetter: Getter.cpp1Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpp1TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};
export const CPP2D = {
    colId: 'cpp2d',
    headerName: 'CPP 2D',
    sortable: false,
    valueGetter: Getter.cpp2Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpp2TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(3),
    hide: true
};
export const CPP3D = {
    colId: 'cpp3d',
    headerName: 'CPP 3D',
    sortable: false,
    valueGetter: Getter.cpp3Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpp3TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(3),
    hide: true
};
export const CPP7D = {
    colId: 'cpp7d',
    headerName: 'CPP 7D',
    sortable: false,
    valueGetter: Getter.cpp7Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpp7TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(7),
    hide: true
};
export const CPP14D = {
    colId: 'cpp14d',
    headerName: 'CPP 14D',
    sortable: false,
    valueGetter: Getter.cpp14Getter,
    valueFormatter: Formatter.numberFormatter(1),
    tooltipValueGetter: Getter.cpp14TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(14),
    hide: true
};
export const CPI = {
    colId: 'cpi',
    headerName: 'CPI',
    sortable: false,
    valueGetter: Getter.cpiGetter,
    type: 'numericColumn',
    width: 40,
    valueFormatter: Formatter.numberFormatter(2)
};
export const LTV360 = {
    colId: 'ltv360',
    headerName: 'LTV 360D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ltvGetter,
    width: 60,
    valueFormatter: Formatter.numberFormatter(2)
};
export const AD_LTV360 = {
    colId: 'adLtv360',
    headerName: 'adLTV 360D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.adLtvGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const FACT_LTV360 = {
    colId: 'factLtv360',
    headerName: 'FACT_LTV360',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.factLtvGetter,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(360),
    hide: true
};
export const SPEND = {
    colId: 'spend',
    headerName: 'Spend',
    field: 'spend',
    aggFunc: 'sum',
    type: 'numericColumn',
    width: 50,
    valueFormatter: Formatter.numberFormatter(0)
};
export const IPM = {
    colId: 'ipm',
    headerName: 'IPM',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ipmGetter,
    tooltipValueGetter: Getter.ipmTooltipGetter,
    width: 40,
    valueFormatter: Formatter.numberFormatter(2)
};
export const CS = {
    colId: 'cs',
    headerName: 'CS',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.csGetter,
    tooltipValueGetter: Getter.csTooltipGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const CS_CREATIVE_REPORT = {
    colId: 'csCreativeReport',
    headerName: 'CS',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.csCreativeReportGetter,
    width: 40,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const CPM = {
    colId: 'cpm',
    headerName: 'CPM',
    type: 'numericColumn',
    sortable: false,
    valueGetter:
    Getter.cpmGetter,
    width: 40,
    valueFormatter: Formatter.numberFormatter(0)
};
export const PRFCST = {
    colId: 'prFcst',
    headerName: 'PrFCST',
    sortable: false,
    valueGetter: Getter.profitFCSTGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(0),
    hide: true
};
export const INST1D = {
    colId: 'inst1d',
    headerName: 'Inst 1D',
    aggFunc: 'custom',
    field: 'inst1',
    type: 'numericColumn',
    hide: true,
};
export const RET1D = {
    colId: 'ret1d',
    headerName: 'Ret 1D',
    sortable: false,
    valueGetter: Getter.retention1Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.retention1TooltipGetter,
    type: 'numericColumn',
    width: 40,
    cellRenderer: Formatter.incompleteDayRenderer(1),
};
export const RET2D = {
    colId: 'ret2d',
    headerName: 'Ret 2D',
    sortable: false,
    valueGetter: Getter.retention2Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.retention2TooltipGetter,
    type: 'numericColumn',
    width: 40,
    cellRenderer: Formatter.incompleteDayRenderer(2),
};
export const C_RET1D = {
    colId: 'cRet1d',
    headerName: 'CRetU 1D',
    sortable: false,
    valueGetter: Getter.retention1Cost,
    valueFormatter: Formatter.numberFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};
export const C_RET2D = {
    colId: 'cRet2d',
    headerName: 'CRetU 2D',
    sortable: false,
    valueGetter: Getter.retention2Cost,
    valueFormatter: Formatter.numberFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(2),
    hide: true
};
export const RPM1D = {
    colId: 'rpm1d',
    headerName: 'RPM 1D',
    sortable: false,
    valueGetter: Getter.retentionPerMile1,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(1),
    hide: true
};
export const RPM2D = {
    colId: 'rpm1d',
    headerName: 'RPM 2D',
    sortable: false,
    valueGetter: Getter.retentionPerMile2,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(1),
    hide: true
};
export const NEG_RET1D = {
    colId: 'negRet1d',
    headerName: 'Neg Ret 1D',
    sortable: false,
    valueGetter: Getter.negRetention1Getter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(1)
};
export const INST2D = {
    colId: 'inst2d',
    headerName: 'Inst 2D',
    aggFunc: 'custom',
    field: 'inst2',
    type: 'numericColumn',
    hide: true,
};
export const NEG_RET2D = {
    colId: 'negRet2d',
    headerName: 'Neg Ret 2D',
    sortable: false,
    valueGetter: Getter.negRetention2Getter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(1)
};
export const INST3D = {
    colId: 'inst3d',
    headerName: 'Inst 3D',
    aggFunc: 'custom',
    field: 'inst3',
    type: 'numericColumn',
    hide: true,
};
export const RET3D = {
    colId: 'ret3d',
    headerName: 'Ret 3D',
    sortable: false,
    valueGetter: Getter.retention3Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.retention3TooltipGetter,
    type: 'numericColumn',
    width: 50,
    cellRenderer: Formatter.incompleteDayRenderer(3),
};
export const NEG_RET3D = {
    colId: 'negRet3d',
    headerName: 'Neg Ret 3D',
    sortable: false,
    valueGetter: Getter.negRetention3Getter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(1)
};
export const INST7D = {
    colId: 'inst7d',
    headerName: 'Inst 7D',
    aggFunc: 'custom',
    field: 'inst7',
    type: 'numericColumn',
    hide: true,
};
export const RET7D = {
    colId: 'ret7d',
    headerName: 'Ret 7D',
    sortable: false,
    valueGetter: Getter.retention7Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.retention7TooltipGetter,
    type: 'numericColumn',
    width: 50,
    cellRenderer: Formatter.incompleteDayRenderer(7)
};
export const NEG_RET7D = {
    colId: 'negRet7d',
    headerName: 'Neg Ret 7D',
    sortable: false,
    valueGetter: Getter.negRetention7Getter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(1)
};
// inapp + ad
export const NET_REVENUE_SKAN = {
    colId: 'netRevenueFullSkan',
    headerName: 'Net Revenue',
    field: 'net_revenue_full_skan',
    aggFunc: 'custom',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
};

export const AD_REVENUE = {
    colId: 'adRevenue',
    headerName: 'Ad Revenue',
    field: 'ad_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const OFW_REVENUE = {
    colId: 'ofwRevenue',
    headerName: 'Offer Wall Revenue',
    field: 'ofw_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const SUB_NET_REVENUE = {
    colId: 'subNetRevenue',
    headerName: 'Net Revenue (Subscriptions)',
    field: 'sub_net_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const SUB_GROSS_REVENUE = {
    colId: 'subGrossRevenue',
    headerName: 'Gross Revenue (Subscriptions)',
    field: 'sub_gross_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const GROSS_ROAS7D = {
    colId: 'grossRoas7d',
    headerName: 'ROAS 7D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas7Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(7),
    hide: true
};
export const IMPRESSIONS = {
    colId: 'impressions',
    headerName: 'Impressions',
    field: 'impressions',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(0),
    hide: true
};
export const PAGE_VIEWS = {
    colId: 'pageViews',
    headerName: 'Page Views',
    field: 'page_views',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(0)
};
export const CLICKS = {
    colId: 'clicks',
    headerName: 'Clicks',
    field: 'clicks',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(0),
    hide: true
};
export const PAYERS = {
    colId: 'payers',
    headerName: 'Payers',
    field: 'payers',
    aggFunc: 'sum',
    type: 'numericColumn',
    width: 50,
    hide: true
};
export const NULL_CV_SHARE = {
    colId: 'null_cv_share',
    headerName: 'Null-CV %',
    sortable: false,
    valueGetter: Getter.nullCvShare,
    valueFormatter: Formatter.percentageFormatter(1),
    cellRenderer: Formatter.incompleteDayRenderer(1),
    type: 'numericColumn',
};
export const ZERO_CV_SHARE = {
    colId: 'zero_cv_share',
    headerName: '0-CV %',
    sortable: false,
    valueGetter: Getter.zeroCvShare,
    valueFormatter: Formatter.percentageFormatter(1),
    cellRenderer: Formatter.incompleteDayRenderer(1),
    type: 'numericColumn',
};
export const NULL_CV_COUNT = {
    colId: 'null_cv_count',
    headerName: 'Null-CV count',
    field: 'null_cv_count',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const ZERO_CV_COUNT = {
    colId: 'zero_cv_count',
    headerName: 'Zero-CV count',
    field: 'zero_cv_count',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYERS1 = {
    colId: 'payers1',
    headerName: 'Payers 1D',
    field: 'payers1',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYERS2 = {
    colId: 'payers2',
    headerName: 'Payers 2D',
    field: 'payers2',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};

export const PAYERS3 = {
    colId: 'payers3',
    headerName: 'Payers 3D',
    field: 'payers3',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true,
};

export const PAYERS7 = {
    colId: 'payers7',
    headerName: 'Payers 7D',
    field: 'payers7',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYERS14 = {
    colId: 'payers14',
    headerName: 'Payers 14D',
    field: 'payers14',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYERS30 = {
    colId: 'payers30',
    headerName: 'Payers 30D',
    field: 'payers30',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};

export const PAYERS3_PERCENT = {
    colId: 'payers3Percent',
    headerName: 'Payers 3D%',
    sortable: false,
    valueGetter: Getter.payers3Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.payers3TooltipGetter,
    type: 'numericColumn',
    width: 65,
    cellRenderer: Formatter.incompleteDayRenderer(3),
    hide: true
};
export const PAYMENTS = {
    colId: 'payments',
    headerName: 'Payments',
    field: 'payments',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS1 = {
    colId: 'payments1',
    headerName: 'Payments 1D',
    field: 'payments1',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS2 = {
    colId: 'payments2',
    headerName: 'Payments 2D',
    field: 'payments2',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS3 = {
    colId: 'payments3',
    headerName: 'Payments 3D',
    field: 'payments3',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS7 = {
    colId: 'payments7',
    headerName: 'Payments 7D',
    field: 'payments7',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS14 = {
    colId: 'payments14',
    headerName: 'Payments 14D',
    field: 'payments14',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const PAYMENTS30 = {
    colId: 'payments30',
    headerName: 'Payments 30D',
    field: 'payments30',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};

export const GROSS_ROAS0D = {
    colId: 'grossRoas0d',
    headerName: 'ROAS 0D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas0Getter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
    hide: true
};
export const GROSS_ROAS1D = {
    colId: 'grossRoas1d',
    headerName: 'ROAS 1D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas1Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};
export const DAU1D = {
    colId: 'dau1d',
    headerName: 'DAU 1D',
    field: 'dau1',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};
export const NEG_DAU1D = {
    colId: 'negDau1d',
    headerName: 'NEG DAU 1D',
    field: 'adj_dau1',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};
export const NEG_DAU2D = {
    colId: 'negDau2d',
    headerName: 'NEG DAU 2D',
    field: 'adj_dau2',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};

export const GROSS_ROAS2D = {
    colId: 'grossRoas2d',
    headerName: 'ROAS 2D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas2Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(3)
};
export const GROSS_ROAS3D = {
    colId: 'grossRoas3d',
    headerName: 'ROAS 3D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas3Getter,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};
export const DAU2D = {
    colId: 'dau2d',
    headerName: 'DAU 2D',
    field: 'dau2',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
};
export const DAU3D = {
    colId: 'dau3d',
    headerName: 'DAU 3D',
    field: 'dau3',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
};
export const NEG_DAU3D = {
    colId: 'negDau3d',
    headerName: 'NEG DAU 3D',
    field: 'adj_dau3',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};

export const DAU7D = {
    colId: 'dau7d',
    headerName: 'DAU 7D',
    field: 'dau7',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
};
export const NEG_DAU7D = {
    colId: 'negDau7d',
    headerName: 'NEG DAU 7D',
    field: 'adj_dau7',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};

export const DAU14D = {
    colId: 'dau14d',
    headerName: 'DAU 14D',
    field: 'dau14',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
};
export const INST14D = {
    colId: 'inst14d',
    headerName: 'Inst 14D',
    aggFunc: 'custom',
    field: 'inst14',
    type: 'numericColumn',
    hide: true,
};
export const RET14D = {
    colId: 'ret14d',
    headerName: 'Ret 14D',
    sortable: false,
    valueGetter: Getter.retention14Getter,
    tooltipValueGetter: Getter.retention14TooltipGetter,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14)
};
export const GROSS_ROAS14D = {
    colId: 'grossRoas14d',
    headerName: 'ROAS 14D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas14Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(14)
};

export const GROSS_ROAS30D = {
    colId: 'grossRoas30d',
    headerName: 'ROAS 30D Gross',
    sortable: false,
    valueGetter: Getter.grossRoas30Getter,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30)
};
export const DAU30D = {
    colId: 'dau30d',
    headerName: 'DAU 30D',
    field: 'dau30',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
};
export const INST30D = {
    colId: 'inst30d',
    headerName: 'Inst 30D',
    aggFunc: 'custom',
    field: 'inst30',
    type: 'numericColumn',
    hide: true,
};
export const RET30D = {
    colId: 'ret30d',
    headerName: 'Ret 30D',
    sortable: false,
    valueGetter: Getter.retention30Getter,
    tooltipValueGetter: Getter.retention30TooltipGetter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(30)
};

export const FCST360 = {
    colId: 'fcst360',
    headerName: 'FCST 360D',
    field: 'fcst_360',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST90 = {
    colId: 'fcst90',
    headerName: 'FCST 90D',
    field: 'fcst_90',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST120 = {
    colId: 'fcst120',
    headerName: 'FCST 120D',
    field: 'fcst_120',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST180 = {
    colId: 'fcst180',
    headerName: 'FCST 180D',
    field: 'fcst_180',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST360_ORGANIC = {
    colId: 'fcst360Organic',
    headerName: 'FCST 360D (organic)',
    field: 'fcst_360_organic',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST360_ORGANIC = {
    colId: 'adFcst360Organic',
    headerName: 'adFCST 360D (organic)',
    field: 'ad_fcst_360_organic',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST360_NEW = {
    colId: 'fcst360New',
    headerName: 'FCST 360D (new)',
    field: 'fcst_360_new',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST90 = {
    colId: 'adFcst90',
    headerName: 'adFCST 90D',
    field: 'ad_fcst_90',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST120 = {
    colId: 'adFcst120',
    headerName: 'adFCST 120D',
    field: 'ad_fcst_120',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST180 = {
    colId: 'adFcst180',
    headerName: 'adFCST 180D',
    field: 'ad_fcst_180',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST360 = {
    colId: 'adFcst360',
    headerName: 'adFCST 360D',
    field: 'ad_fcst_360',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST_360_D7_FIXED = {
    colId: 'adFcst360',
    headerName: 'adFCST 360D',
    field: 'ad_fcst_360_d7_fixed',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const CONVERSIONS = {
    colId: 'conversions',
    headerName: 'Conversions',
    field: 'installs',
    aggFunc: 'sum',
    type: 'numericColumn',
};
export const REENG = {
    colId: 'reeng',
    headerName: 'ReEngagements',
    field: 're_engagements',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true
};
export const REENG_PERCENT = {
    colId: 'reengPercent',
    headerName: 'Re-eng%',
    sortable: false,
    valueGetter: Getter.reEngagementsShareGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(0),
};
export const VTA = {
    colId: 'vta',
    headerName: 'VTA',
    field: 'vta',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true
};
export const VTA_PERCENT = {
    colId: 'vtaPercent',
    headerName: 'VTA%',
    sortable: false,
    valueGetter: Getter.vtaGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
    hide: true
};
export const CPC = {
    colId: 'cpc',
    headerName: 'CPC',
    sortable: false,
    valueGetter: Getter.cpcGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
};
export const CTR = {
    colId: 'ctr',
    headerName: 'CTR',
    sortable: false,
    valueGetter: Getter.ctrGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const IR = {
    colId: 'ir',
    headerName: 'IR',
    sortable: false,
    valueGetter: Getter.irGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const CVR = {
    colId: 'cvr',
    headerName: 'CVR',
    sortable: false,
    valueGetter: Getter.cvrGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const tCPA = {
    colId: 'tCpa',
    headerName: 'tCPA',
    field: 'target_cpa',
    type: 'numericColumn',
    aggFunc: 'avg',
    valueFormatter: Formatter.numberFormatter(2)
};
export const CVR_IMPRESSIONS_PPV = {
    colId: 'cvrImpressionsPpv',
    headerName: 'CVR PPV/Impr',
    sortable: false,
    valueGetter: Getter.cr('page_views', 'impressions'),
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const CVR_IMPRESSIONS_INSTALLS = {
    colId: 'cvrImpressionsInstalls',
    headerName: 'CVR Inst/Impr',
    sortable: false,
    valueGetter: Getter.cr('installs', 'impressions'),
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const CVR_PPV_INSTALLS = {
    colId: 'cvrImpressionsInstalls',
    headerName: 'CVR Inst/PPV',
    sortable: false,
    valueGetter: Getter.cr('installs', 'page_views'),
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(2),
};
export const ARPU_SKAN = {
    colId: 'arpu',
    headerName: 'ARPU',
    sortable: false,
    valueGetter: Getter.cr('net_revenue', 'installs'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
};


export const AD_REVENUE0D = {
    colId: 'adRevenue0d',
    headerName: 'Ad Revenue 0D',
    field: 'ad_revenue0',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(0)
};
export const AD_REVENUE1D = {
    colId: 'adRevenue1d',
    headerName: 'Ad Revenue 1D',
    field: 'ad_revenue1',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1)
};
export const AD_REVENUE3D = {
    colId: 'adRevenue3d',
    headerName: 'Ad Revenue 3D',
    field: 'ad_revenue3',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};
export const AD_REVENUE7D = {
    colId: 'adRevenue7d',
    headerName: 'Ad Revenue 7D',
    field: 'ad_revenue7',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7)
};
export const AD_REVENUE14D = {
    colId: 'adRevenue14d',
    headerName: 'Ad Revenue 14D',
    field: 'ad_revenue14',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14)
};
export const AD_REVENUE30D = {
    colId: 'adRevenue30d',
    headerName: 'Ad Revenue 30D',
    field: 'ad_revenue30',
    type: 'numericColumn',
    aggFunc: 'sum',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30)
};
export const INST1D_CREATIVE_REPORT = {
    colId: 'inst1dCreativeReport',
    headerName: 'Inst1d',
    aggFunc: 'custom',
    field: 'inst1dCreativeReport',
    type: 'numericColumn',
    hide: true,
};
export const INST3D_CREATIVE_REPORT = {
    colId: 'inst3dCreativeReport',
    headerName: 'Inst3d',
    aggFunc: 'custom',
    field: 'inst3dCreativeReport',
    type: 'numericColumn',
    hide: true,
};
export const INST7D_CREATIVE_REPORT = {
    colId: 'inst7dCreativeReport',
    headerName: 'Inst7d',
    aggFunc: 'custom',
    field: 'inst7dCreativeReport',
    type: 'numericColumn',
    hide: true,
};
export const RET1D_CREATIVE_REPORT = {
    colId: 'ret1dCreativeReport',
    headerName: 'Ret1d',
    sortable: false,
    valueGetter: Getter.retention1CreativeReportGetter,
    valueFormatter: Formatter.percentageFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
};

export const RET3D_CREATIVE_REPORT = {
    colId: 'ret3dCreativeReport',
    headerName: 'Ret3d',
    sortable: false,
    valueGetter: Getter.retention3CreativeReportGetter,
    valueFormatter: Formatter.percentageFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
};

export const RET7D_CREATIVE_REPORT = {
    colId: 'ret7dCreativeReport',
    headerName: 'Ret 7D',
    sortable: false,
    valueGetter: Getter.retention7CreativeReportGetter,
    valueFormatter: Formatter.percentageFormatter(1),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
};


//UAM LTV and ROI columns config
export const LTV360_OLD_7D = {
    colId: 'ltv360',
    headerName: 'LTV360 old 7D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ltvGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true,
};
export const LTV360_OLD = {
    colId: 'ltv360New',
    headerName: 'LTV360 old',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ltv360OldGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const AD_LTV360_OLD_7D = {
    colId: 'adLtv360',
    headerName: 'adLTV360 old 7D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.adLtvGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const ROAS_OLD = {
    colId: 'roiNew',
    headerName: 'ROAS old',
    sortable: false,
    valueGetter: Getter.roasNewGetter,
    type: 'numericColumn',
    valueFormatter: Formatter.percentageFormatter(0),
    width: 70,
    hide: true
};
export const ROAS = {
    colId: 'roas',
    headerName: 'ROAS',
    sortable: false,
    valueGetter: Getter.roas360Getter,
    type: 'numericColumn',
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const ROAS_BLENDED = {
    colId: 'roasBlended',
    headerName: 'ROAS (blended)',
    sortable: false,
    valueGetter: Getter.roas360BlendedGetter,
    type: 'numericColumn',
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const ROAS90 = {
    colId: 'roas90',
    headerName: 'ROAS 90D',
    sortable: false,
    valueGetter: Getter.roas90Getter,
    type: 'numericColumn',
    hide: true,
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const ROAS120 = {
    colId: 'roas120',
    headerName: 'ROAS 120D',
    sortable: false,
    valueGetter: Getter.roas120Getter,
    type: 'numericColumn',
    hide: true,
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const ROAS180 = {
    colId: 'roas180',
    headerName: 'ROAS 180D',
    sortable: false,
    valueGetter: Getter.roas180Getter,
    type: 'numericColumn',
    hide: true,
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const PROFIT_360 = {
    colId: 'profit360',
    headerName: 'Profit 360D',
    sortable: false,
    valueGetter: Getter.profit360Getter,
    type: 'numericColumn',
    width: 60,
    valueFormatter: Formatter.numberFormatter(2),
    hidden: true
};

export const ROAS_DRIFTING = {
    colId: 'roasDrifting',
    headerName: 'ROAS drifting',
    sortable: false,
    valueGetter: Getter.roasDriftingGetter,
    valueFormatter: Formatter.percentageFormatter(0),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.roasDriftingRenderer(),
};


export const ROAS_TEST_DRIFTING = {
    colId: 'roasDrifting',
    headerName: 'ROAS Test drifting',
    sortable: false,
    valueGetter: Getter.roasTestDriftingGetter,
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.roasDriftingRenderer(),
    hide: true
};

export const ROAS_TEST = {
    colId: 'roasTest',
    headerName: 'ROAS test',
    sortable: false,
    valueGetter: Getter.roasTestGetter,
    type: 'numericColumn',
    hide: true,
    width: 60,
    valueFormatter: Formatter.percentageFormatter(0),
};
export const LTV360_CF_COMBINE = {
    colId: 'ltv360CfCombine',
    headerName: 'LTV 360D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ltvCfCombineGetter,
    width: 60,
    valueFormatter: Formatter.numberFormatter(2),
};
export const AD_LTV360_CF_COMBINE = {
    colId: 'adLtv360CfCombine',
    headerName: 'adLTV 360D',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.adLtvCfCombineGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const LTV360_CF_TEST = {
    colId: 'ltv360CfTest',
    headerName: 'LTV 360D Test',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.ltvCfTestGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};
export const AD_LTV360_CF_TEST = {
    colId: 'adLtv360CfTest',
    headerName: 'adLTV 360D Test',
    type: 'numericColumn',
    sortable: false,
    valueGetter: Getter.adLtvCfTestGetter,
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_FCST360 = {
    colId: 'inappFcst360',
    headerName: 'inappFCST 360D',
    valueGetter: Getter.inappFcst360,
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true,
};

export const FCST360_CF_D7_FIXED_TEST = {
    colId: 'fcst360CfD7FixedTest',
    headerName: 'FCST 360 7d fixed test',
    field: 'fcst_360_cf_d7_fixed_test',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};

export const AD_FCST360_CF_D7_FIXED_TEST = {
    colId: 'adFcst360CfD7FixedTest',
    headerName: 'adFCST 360 7d fixed test',
    field: 'ad_fcst_360_cf_d7_fixed_test',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};

export const FCST360_D7_FIXED = {
    colId: 'fcst360CfCombine7dFixed',
    headerName: 'FCST 360 7d fixed',
    field: 'fcst_360_d7_fixed',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const FCST360_CF_TEST = {
    colId: 'fcst360CfTest',
    headerName: 'FCST 360D test',
    field: 'fcst_360_cf_test',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const AD_FCST360_CF_TEST = {
    colId: 'adFcst360CfTest',
    headerName: 'adFCST 360D test',
    field: 'ad_fcst_360_cf_test',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    aggFunc: 'sum',
    hide: true,
};
export const LTVPPU_CF_COMBINE = {
    colId: 'ltvppuCfCombine',
    headerName: 'LTVppu',
    sortable: false,
    valueGetter: Getter.ltvPpuCfCombineGetter,
    type: 'numericColumn',
    width: 60,
    valueFormatter: Formatter.numberFormatter(0)
};
export const ROAS_FACT_SKAN = {
    colId: 'roasFct',
    headerName: 'Fact ROAS',
    sortable: false,
    valueGetter: Getter.roasFctSkanGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.percentageFormatter(1),
};
export const ROAS0D_FACT = {
    colId: 'factRoas0d',
    headerName: 'Fact ROAS 0D',
    sortable: false,
    valueGetter: Getter.factRoas0Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(0),
    hide: true
};
export const ROAS1D_FACT = {
    colId: 'factRoas1d',
    headerName: 'Fact ROAS 1D',
    sortable: false,
    valueGetter: Getter.factRoas1Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(1)
};
export const ROAS2D_FACT = {
    colId: 'factRoas2d',
    headerName: 'Fact ROAS 2D',
    sortable: false,
    valueGetter: Getter.factRoas2Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(2)
};
export const ROAS3D_FACT = {
    colId: 'factRoas3d',
    headerName: 'Fact ROAS 3D',
    sortable: false,
    valueGetter: Getter.factRoas3Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(3)
};
export const ROAS7D_FACT = {
    colId: 'factRoas7d',
    headerName: 'Fact ROAS 7D',
    sortable: false,
    valueGetter: Getter.factRoas7Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(7)
};
export const ROAS14D_FACT = {
    colId: 'factRoas14d',
    headerName: 'Fact ROAS 14D',
    sortable: false,
    valueGetter: Getter.factRoas14Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(14)
};
export const ROAS30D_FACT = {
    colId: 'factRoas30d',
    headerName: 'Fact ROAS 30D',
    sortable: false,
    valueGetter: Getter.factRoas30Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(30)
};
export const ROAS60D_FACT = {
    colId: 'factRoas60d',
    headerName: 'Fact ROAS 60D',
    sortable: false,
    valueGetter: Getter.factRoas60Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(60)
};
export const ROAS90D_FACT = {
    colId: 'factRoas90d',
    headerName: 'Fact ROAS 90D',
    sortable: false,
    valueGetter: Getter.factRoas90Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(90)
};
export const ROAS180D = {
    colId: 'factRoas180d',
    headerName: 'Fact ROAS 180D',
    sortable: false,
    valueGetter: Getter.factRoas180Getter,
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(180)
};

export const ROAS1D_FACT_WEB = {
    colId: 'factRoas1d',
    headerName: 'Fact ROAS 1D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue1', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(1)
};
export const ROAS3D_FACT_WEB = {
    colId: 'factRoas3d',
    headerName: 'Fact ROAS 3D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue3', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(3)
};
export const ROAS7D_FACT_WEB = {
    colId: 'factRoas7d',
    headerName: 'Fact ROAS 7D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue7', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    width: 60,
    cellRenderer: Formatter.incompleteDayRenderer(7)
};
export const ROAS14D_FACT_WEB = {
    colId: 'factRoas14d',
    headerName: 'Fact ROAS 14D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue14', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(14)
};
export const ROAS30D_FACT_WEB = {
    colId: 'factRoas30d',
    headerName: 'Fact ROAS 30D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue30', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(30)
};
export const ROAS60D_FACT_WEB = {
    colId: 'factRoas60d',
    headerName: 'Fact ROAS 60D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue60', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(60)
};
export const ROAS90D_FACT_WEB = {
    colId: 'factRoas90d',
    headerName: 'Fact ROAS 90D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue90', 'spend'),
    valueFormatter: Formatter.percentageFormatter(2),
    type: 'numericColumn',
    hide: true,
    cellRenderer: Formatter.incompleteDayRenderer(90)
};
export const WHALES = {
    colId: 'whales',
    headerName: 'Whales',
    field: 'whales',
    aggFunc: 'sum',
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(0),
};
export const C2W = {
    colId: 'c2w',
    headerName: 'C2W',
    sortable: false,
    valueGetter: Getter.c2wGetter,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(2)
};
export const CPW = {
    colId: 'cpw',
    headerName: 'CPW',
    sortable: false,
    valueGetter: Getter.cpwGetter,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2)
};
export const ROAS_MIN = {
    colId: 'roasMin',
    headerName: 'RoAS min',
    sortable: false,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(0),
    valueGetter: (params) => Getter.roasMin(params, moment(params.startDate), moment(params.endDate))
}
export const ROAS_MAX = {
    colId: 'roasMax',
    headerName: 'RoAS max',
    sortable: false,
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.percentageFormatter(0),
    valueGetter: (params) => Getter.roasMax(params, moment(params.startDate), moment(params.endDate))
}
export const AVG_COHORT_DAY = {
    colId: 'avgCohortDay',
    headerName: 'Avg Cohort day',
    sortable: false,
    type: 'numericColumn',
    hide: true,
    valueGetter: (params) => Getter.avgCohortDay(params, moment(params.startDate), moment(params.endDate))
}

export const NET_REVENUE_WEB_REPORT = {
    colId: 'netRevenue',
    headerName: 'Net Revenue',
    field: 'net_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_1D_WEB_REPORT = {
    colId: 'netRevenue1',
    headerName: 'Net Revenue 1D',
    field: 'net_revenue1',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_3D_WEB_REPORT = {
    colId: 'netRevenue3',
    headerName: 'Net Revenue 3D',
    field: 'net_revenue3',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_7D_WEB_REPORT = {
    colId: 'netRevenue7',
    headerName: 'Net Revenue 7D',
    field: 'net_revenue7',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_14D_WEB_REPORT = {
    colId: 'netRevenue14',
    headerName: 'Net Revenue 14D',
    field: 'net_revenue14',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_30D_WEB_REPORT = {
    colId: 'netRevenue30',
    headerName: 'Net Revenue 30D',
    field: 'net_revenue30',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_60D_WEB_REPORT = {
    colId: 'netRevenue60',
    headerName: 'Net Revenue 60D',
    field: 'net_revenue60',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_90D_WEB_REPORT = {
    colId: 'netRevenue90',
    headerName: 'Net Revenue 90D',
    field: 'net_revenue90',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_TOTAL = {
    colId: 'netRevenueTotal',
    headerName: 'Net Revenue',
    field: 'net_revenue_total',
    aggFunc: 'custom',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const NET_REVENUE_TOTAL_0D = {
    colId: 'netRevenueTotal1d',
    headerName: 'Net Revenue 0D',
    field: 'net_revenue_total_0',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(0),
    aggFunc: 'custom',
    hide: true
};

export const NET_REVENUE_TOTAL_1D = {
    colId: 'netRevenueTotal1d',
    headerName: 'Net Revenue 1D',
    field: 'net_revenue_total_1',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
    aggFunc: 'custom',
    hide: true
};

export const NET_REVENUE_TOTAL_2D = {
    colId: 'netRevenueTotal2d',
    headerName: 'Net Revenue 2D',
    field: 'net_revenue_total_2',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};

export const NET_REVENUE_TOTAL_3D = {
    colId: 'netRevenueTotal3d',
    headerName: 'Net Revenue 3D',
    field: 'net_revenue_total_3',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};

export const NET_REVENUE_TOTAL_7D = {
    colId: 'netRevenueTotal7d',
    headerName: 'Net Revenue 7D',
    field: 'net_revenue_total_7',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7)
};

export const NET_REVENUE_TOTAL_14D = {
    colId: 'netRevenueTotal14d',
    headerName: 'Net Revenue 14D',
    field: 'net_revenue_total_14',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14)
};

export const NET_REVENUE_TOTAL_30D = {
    colId: 'netRevenueTotal30d',
    headerName: 'Net Revenue 30D',
    field: 'net_revenue_total_30',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30)
};

export const NET_REVENUE_TOTAL_60D = {
    colId: 'netRevenueTotal60d',
    headerName: 'Net Revenue 60D',
    field: 'net_revenue_total_60',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30)
};

export const NET_REVENUE_TOTAL_90D = {
    colId: 'netRevenueTotal90d',
    headerName: 'Net Revenue 90D',
    field: 'net_revenue_total_90',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(90)
};

export const NET_REVENUE_TOTAL_180D = {
    colId: 'netRevenueTotal180d',
    headerName: 'Net Revenue 180D',
    field: 'net_revenue_total_180',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(180)
};

export const NET_REVENUE_TOTAL_360D = {
    colId: 'netRevenueTotal360d',
    headerName: 'Net Revenue 360D',
    field: 'net_revenue_total_360',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(360)
};

export const GROSS_REVENUE_TOTAL = {
    colId: 'grossRevenueTotal',
    headerName: 'Gross Revenue',
    field: 'gross_revenue_total',
    aggFunc: 'custom',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const GROSS_REVENUE0D = {
    colId: 'grossRevenueTotal0d',
    headerName: 'Gross Revenue 0D',
    field: 'gross_revenue_total_0',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2)
};

export const GROSS_REVENUE_TOTAL_1D = {
    colId: 'grossRevenueTotal1d',
    headerName: 'Gross Revenue 1D',
    field: 'gross_revenue_total_1',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
    aggFunc: 'custom',
    hide: true
};

export const GROSS_REVENUE_TOTAL_2D = {
    colId: 'grossRevenueTotal2d',
    headerName: 'Gross Revenue 2D',
    field: 'gross_revenue_total_2',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};

export const GROSS_REVENUE_TOTAL_3D = {
    colId: 'grossRevenueTotal3d',
    headerName: 'Gross Revenue 3D',
    field: 'gross_revenue_total_3',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3)
};

export const GROSS_REVENUE_TOTAL_7D = {
    colId: 'grossRevenueTotal7d',
    headerName: 'Gross Revenue 7D',
    field: 'gross_revenue_total_7',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7)
};

export const GROSS_REVENUE_TOTAL_14D = {
    colId: 'grossRevenueTotal14d',
    headerName: 'Gross Revenue 14D',
    field: 'gross_revenue_total_14',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14)
};

export const GROSS_REVENUE_TOTAL_30D = {
    colId: 'grossRevenueTotal30d',
    headerName: 'Gross Revenue 30D',
    field: 'gross_revenue_total_30',
    type: 'numericColumn',
    aggFunc: 'custom',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30)
};

export const PAYERS1_PERCENT = {
    colId: 'payers1Percent',
    headerName: 'Payers 1D%',
    sortable: false,
    valueGetter: Getter.payers1Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.payers1TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};

export const PAYERS2_PERCENT = {
    colId: 'payers2Percent',
    headerName: 'Payers 2D%',
    sortable: false,
    valueGetter: Getter.payers2Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.payers2TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};

export const PAYERS7_PERCENT = {
    colId: 'payers7Percent',
    headerName: 'Payers 7D%',
    sortable: false,
    valueGetter: Getter.payers7Getter,
    valueFormatter: Formatter.percentageFormatter(1),
    tooltipValueGetter: Getter.payers7TooltipGetter,
    type: 'numericColumn',
    cellRenderer: Formatter.incompleteDayRenderer(7),
    hide: true
};

export const INAPP_NET_REVENUE = {
    colId: 'inappRevenue',
    headerName: 'Inapp Net Revenue',
    field: 'net_revenue',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_NET_REVENUE_1D = {
    colId: 'inappNetRevenue1d',
    headerName: 'Inapp Net Revenue 1D',
    field: 'net_revenue1',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_NET_REVENUE_3D = {
    colId: 'inappNetRevenue3d',
    headerName: 'Inapp Net Revenue 3D',
    field: 'net_revenue3',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_NET_REVENUE_7D = {
    colId: 'inappNetRevenue7d',
    headerName: 'Inapp Net Revenue 7D',
    field: 'net_revenue7',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_NET_REVENUE_14D = {
    colId: 'inappNetRevenue14d',
    headerName: 'Inapp Net Revenue 14D',
    field: 'net_revenue14',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const INAPP_NET_REVENUE_30D = {
    colId: 'inappNetRevenue30d',
    headerName: 'Inapp Net Revenue 30D',
    field: 'net_revenue30',
    aggFunc: 'sum',
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    hide: true
};

export const ARPPU = {
    colId: 'arppu',
    headerName: 'ARPPU',
    sortable: false,
    valueGetter: Getter.cr('net_revenue', 'payers'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
};

export const ARPPU1D = {
    colId: 'arppu1d',
    headerName: 'ARPPU 1D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue1', 'payers1'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
    hide: true
};

export const ARPPU3D = {
    colId: 'arppu3d',
    headerName: 'ARPPU 3D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue3', 'payers3'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3),
    hide: true
};

export const ARPPU7D = {
    colId: 'arppu7d',
    headerName: 'ARPPU 7D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue7', 'payers7'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7),
    hide: true
};

export const ARPPU14D = {
    colId: 'arppu14d',
    headerName: 'ARPPU 14D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue14', 'payers14'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14),
    hide: true
};

export const ARPPU30D = {
    colId: 'arppu30d',
    headerName: 'ARPPU 30D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue30', 'payers30'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30),
    hide: true
};

export const ARPU_1D = {
    colId: 'arpu1d',
    headerName: 'ARPU 1D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue1', 'installs'),
    type: 'numericColumn',
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
};

export const ARPU_3D = {
    colId: 'arpu3d',
    headerName: 'ARPU 3D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue3', 'installs'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3),
};

export const ARPU_7D = {
    colId: 'arpu7d',
    headerName: 'ARPU 7D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue7', 'installs'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7),
};

export const ARPU_14D = {
    colId: 'arpu14d',
    headerName: 'ARPU 14D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue14', 'installs'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14),
};

export const ARPU_30D = {
    colId: 'arpu30d',
    headerName: 'ARPU 30D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue30', 'installs'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30),
};

export const AOV_1D = {
    colId: 'aov1d',
    headerName: 'AOV 1D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue1', 'payments1'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
}

export const AOV_3D = {
    colId: 'aov3d',
    headerName: 'AOV 3D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue3', 'payments3'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3),
}

export const AOV_7D = {
    colId: 'aov7d',
    headerName: 'AOV 7D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue7', 'payments7'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7),
}

export const AOV_14D = {
    colId: 'aov14d',
    headerName: 'AOV 14D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue14', 'payments14'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14),
}

export const AOV_30D = {
    colId: 'aov30d',
    headerName: 'AOV 30D',
    sortable: false,
    valueGetter: Getter.cr('net_revenue30', 'payments30'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30),
}

export const OPP_1D = {
    colId: 'opp1d',
    headerName: 'OPP 1D',
    sortable: false,
    valueGetter: Getter.cr('payments1', 'payers1'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(1),
}

export const OPP_3D = {
    colId: 'opp3d',
    headerName: 'OPP 3D',
    sortable: false,
    valueGetter: Getter.cr('payments3', 'payers3'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(3),
}

export const OPP_7D = {
    colId: 'opp7d',
    headerName: 'OPP 7D',
    sortable: false,
    valueGetter: Getter.cr('payments7', 'payers7'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(7),
}

export const OPP_14D = {
    colId: 'opp14d',
    headerName: 'OPP 14D',
    sortable: false,
    valueGetter: Getter.cr('payments14', 'payers14'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(14),
}

export const OPP_30D = {
    colId: 'opp30d',
    headerName: 'OPP 30D',
    sortable: false,
    valueGetter: Getter.cr('payments30', 'payers30'),
    type: 'numericColumn',
    hide: true,
    valueFormatter: Formatter.numberFormatter(2),
    cellRenderer: Formatter.incompleteDayRenderer(30),
}